import { FC, useEffect, useState } from 'react';
import { Card, CardContent, Stack, Typography, Unstable_Grid2 as Grid, Divider, Switch, Chip } from '@mui/material';
import { QuillEditor } from 'components/quill-editor';
import { useAuth } from 'hooks/use-auth';
import { useLogger } from 'hooks/use-logger';
import EnterprisePricingCard from './enterprise-pricing-card';

interface IProps {
    isEnterprise: boolean;
}

export const ManageSmartAccess: FC<IProps> = ({ isEnterprise }) => {

    const auth = useAuth();
    const { organisation } = useAuth();
    const [log] = useLogger();
    const [checked, setChecked] = useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        log(auth.authUser?.email ?? '', "Organisation");
    }, []);

    return (
        <Stack spacing={4}>
            {isEnterprise &&
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid xs={12} md={2}>
                                <Typography variant="h6">
                                    Smart Access
                                </Typography>
                            </Grid>
                            <Grid xs={12} md={10}>
                                <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                                Automatically enable Smart Access for all Assets with all Users
                                <br /><Chip label="TODO Implement or Hide" color='warning' />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }

            {!isEnterprise &&
                <>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                    <Typography variant="h6">
                                        Smart Access
                                    </Typography>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Typography variant="h5">
                                        Enterprise Subscription Plan only.
                                    </Typography>
                                    <br /><br />
                                    Our <strong>Enterprise</strong> plan offers expanded features to streamline your IP management including multiple user licenses, seamless integration to your secure storage, Smart Access, Smart NDAs, and comprehensive reporting dashboards
                                    <br /><br />
                                    The <strong>Enterprise</strong> plan empowers your team to collaborate more efficiently and take full advantage of Peerdweb's advanced capabilities. Upgrade today to unlock the full potential of your IP portfolio management.
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid xs={12} md={2}>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Stack spacing={3}>
                                        <EnterprisePricingCard />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            }
        </Stack >
    );
};

ManageSmartAccess.propTypes = {

};
