import { Button, Card, Container, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import { FC } from "react";
import CheckIcon from '@mui/icons-material/Check';

const EnterprisePricingCard: FC = () => {
    return (
        <Grid container>
            <Grid item id='enterprise' xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                <Container sx={{ display: "flex", flexDirection: "column", gap: "8px", p: 0, pb: 3 }} >
                    <Typography variant="h4" sx={{ fontSize: "24px !important", fontWeight: 600, display: "flex", justifyContent: "space-between", }}>
                        Upgrade to Enterprise
                    </Typography>
                    <Typography color="#101227" fontSize={"20px"} sx={{ fontWeight: 500 }}>
                        Get in touch
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#515260" }}>
                        Suitable for large organisations looking to protect multiple assets across teams.
                    </Typography>
                </Container>
                <Divider sx={{ width: "100%" }} />
                <Container sx={{ display: "flex", minHeight: "295px", padding: "24px", flexDirection: "column", justifyContent: "space-between", }}>
                    <List sx={{ display: "flex", flexDirection: "column", gap: "8px", p: 0, alignItems: "flex-start", }}>
                        <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "8px", p: 0 }}>
                            <Typography variant="body2" sx={{ fontSize: '14px !important' }}>Everything in FREE plan, plus:</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "8px", p: 0 }}>
                            <CheckIcon sx={{ color: "#24BA84" }} />
                            <Typography variant="body2" sx={{ fontSize: '14px !important' }}>Multiple user licences</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "8px", p: 0 }}>
                            <CheckIcon sx={{ color: "#24BA84" }} />
                            <Typography variant="body2" sx={{ fontSize: '14px !important' }}>Integration to client storage</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "8px", p: 0, fontSize: 14 }}>
                            <CheckIcon sx={{ color: "#24BA84" }} />
                            <Typography variant="body2" sx={{ fontSize: '14px !important' }}>Smart Access</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "8px", p: 0, fontSize: 14 }}>
                            <CheckIcon sx={{ color: "#24BA84" }} />
                            <Typography variant="body2" sx={{ fontSize: '14px !important' }}>Smart NDAs</Typography>
                        </ListItem>
                        <ListItem sx={{ display: "flex", alignItems: "flex-start", gap: "8px", p: 0, fontSize: 14 }}>
                            <CheckIcon sx={{ color: "#24BA84" }} />
                            <Typography variant="body2" sx={{ fontSize: '14px !important' }}>Reporting dashboards</Typography>
                        </ListItem>
                    </List>
                    <Button id='homepage-enterprise-button' variant='contained' sx={{ backgroundColor: '#22265D', width: "100%" }} href={`/contact-us`}>
                        Get in touch
                    </Button>
                </Container>
            </Grid>
        </Grid>
    );
};

export default EnterprisePricingCard;