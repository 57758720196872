import { Asset, UpdateAssetRequest, NewAssetRequest, AssetStorage } from 'types/assets/asset';
import qs from 'qs';
import { AssetSearchRequest, AssetSearchResponse } from 'types/search/asset-search';
import { PeerdwebUser } from 'types/users/user';
import { UserSearchRequest, UserSearchResponse } from 'types/search/user-search';
import axios, { AxiosInstance } from "axios";
import { ValidateAssetResponse } from 'types/validate/validate';
import { SmartAccessActivityLogResponse } from "types/smart-access/smart-access-activity";
import { SmartAccessListResponse, SmartAccessResponse } from "types/smart-access/smart-access";
import { Organisation, OrganisationsResponse } from 'types/organisation/organisation';
import { CreateProjectRequest, Project, ProjectSearchRequest, ProjectSearchResponse, UpdateProjectRequest } from 'types/project/project';
import { getAuth } from '@firebase/auth';
import { firebaseApp } from 'libs/firebase';
import { DateRangeSearchRequest } from 'types/analytics/date-range-search-request';
import { AssetDownloads } from 'types/analytics/asset-downloads';

export default class PeerdwebService {

    /* #region constructor / refresh token */
    private _peerdwebApi: AxiosInstance;

    constructor() {
        this._peerdwebApi = axios.create({
            baseURL: process.env.REACT_APP_PEERDWEB_API_BASE_URL,
            headers: {
                "Content-type": "application/json"
            }
        });
    }

    async refreshToken() {
        const token = await getAuth(firebaseApp).currentUser?.getIdToken();
        this._peerdwebApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };
    /* #endregion */



    /* #region Asset */
    async searchAssets(searchRequest: AssetSearchRequest) {
        await this.refreshToken();
        const query = qs.stringify(searchRequest);
        return this._peerdwebApi.get<AssetSearchResponse>(`/assets?${query}`);
    };

    async createAsset(asset: NewAssetRequest) {
        await this.refreshToken();
        return this._peerdwebApi.post<Asset>(`/assets`, asset);
    };

    async getAssetById(id: string, userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<Asset>(`/assets/${id}?userId=${userId}`);
    };

    async getAssetStorage(assetId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<AssetStorage>(`/assets/${assetId}/storage`);
    };

    async updateAsset(asset: UpdateAssetRequest) {
        await this.refreshToken();
        return this._peerdwebApi.put<Asset>(`/assets/${asset._id}/`, asset);
    };

    async validateAssetHash(assetHash: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<ValidateAssetResponse>(`/validation/asset/${assetHash}`);
    }
    /* #endregion */



    /* #region User  */
    async getUserByFirebaseUid(firebaseUid: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<PeerdwebUser>(`/users/uid/${firebaseUid}`);
    };

    async createUser(user: PeerdwebUser) {
        await this.refreshToken();
        return this._peerdwebApi.post<PeerdwebUser>(`/users`, user);
    };

    async updateUser(peerdwebUser: PeerdwebUser) {
        await this.refreshToken();
        return this._peerdwebApi.put<PeerdwebUser>(`/users/${peerdwebUser._id}/`, peerdwebUser);
    };

    async searchUsers(searchRequest: UserSearchRequest) {
        await this.refreshToken();
        const query = qs.stringify(searchRequest);
        return this._peerdwebApi.get<UserSearchResponse>(`/users?${query}`);
    };
    /* #endregion */



    /* #region Organisation */
    async createOrganisationFromPeerdwebUser(user: PeerdwebUser) {
        await this.refreshToken();
        const organisation = {
            name: user.displayName + "'s Organisation",
            owner: user._id,
            credits: 5,
            seatCount: 1,
            monthlyCredits: 0,
            subscriptionLevel: 'free'
        };
        return this._peerdwebApi.post<Organisation>(`/organisations`, organisation);
    };

    async getDefaultOrganisationByUserId(userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<Organisation>(`/organisations/user/${userId}/default`);
    };

    async getOrganisations(userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<OrganisationsResponse>(`/organisations/user/${userId}`);
    };

    async getOrganisationById(organisationId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<Organisation>(`/organisations/${organisationId}`);
    };

    async updateOrganisation(organisation: Organisation) {
        await this.refreshToken();
        return this._peerdwebApi.put<Organisation>(`/organisations/${organisation._id}/`, organisation);
    };
    /* #endregion */




    /* #region Project */
    async createProject(createProjectRequest: CreateProjectRequest) {
        await this.refreshToken();
        const project = {
            name: createProjectRequest.name,
            ownerId: createProjectRequest.ownerId,
            organisationId: createProjectRequest.organisationId
        };
        return this._peerdwebApi.post<Project>(`/projects`, project);
    };

    async searchProjects(searchRequest: ProjectSearchRequest) {
        await this.refreshToken();
        const query = qs.stringify(searchRequest);
        return this._peerdwebApi.get<ProjectSearchResponse>(`/projects?${query}`);
    };

    async getProjectById(id: string, userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<Project>(`/projects/${id}?userId=${userId}`);
    };

    async updateProject(project: UpdateProjectRequest) {
        await this.refreshToken();
        return this._peerdwebApi.put<Project>(`/projects/${project._id}/`, project);
    };
    /* #endregion */



    /* #region Super Admin */
    async superAdminGetAssets() {
        await this.refreshToken();
        return this._peerdwebApi.get<AssetSearchResponse>(`/super-admin/assets`);
    };

    async superAdminGetUsers() {
        await this.refreshToken();
        return this._peerdwebApi.get<UserSearchResponse>(`/super-admin/users`);
    };

    async superAdminGetSmartAccesses() {
        await this.refreshToken();
        return this._peerdwebApi.get<SmartAccessListResponse>(`/super-admin/smart-accesses`);
    };

    async superAdminGetUserAssets(userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<AssetSearchResponse>(`/super-admin/users/assets/${userId}`);
    };

    async superAdminGetOrganisations() {
        await this.refreshToken();
        return this._peerdwebApi.get<OrganisationsResponse>(`/super-admin/organisations`);
    };
    /* #endregion */



    /* #region Smart Access */
    async getSmartAccessActivityLogByAssetId(assetId: string) {
        await this.refreshToken();
        var response = this._peerdwebApi.get<SmartAccessActivityLogResponse>(`/smart-access/activity/${assetId}`);
        return response;
    };

    async getSmartAccessByAssetAndUser(assetId: string, userId: string) {
        await this.refreshToken();
        var response = this._peerdwebApi.get<SmartAccessResponse>(`/smart-access/access/${assetId}/${userId}`);
        return response;
    };

    async getIncomingSmartAccessList(userId: string) {
        await this.refreshToken();
        var response = this._peerdwebApi.get<SmartAccessListResponse>(`/smart-access/list/incoming/${userId}`);
        return response;
    };

    async getOutgoingSmartAccessList(userId: string) {
        await this.refreshToken();
        var response = this._peerdwebApi.get<SmartAccessListResponse>(`/smart-access/list/outgoing/${userId}`);
        return response;
    };

    async inviteSmartAccess(assetId: string, recipientId: string, ownerId: string) {
        await this.refreshToken();
        return this._peerdwebApi.post<void>(`/smart-access/invite`, { assetId, recipientId, ownerId });
    };

    async revokeSmartAccess(smartAccessId: string, ownerId: string) {
        await this.refreshToken();
        return this._peerdwebApi.post<void>(`/smart-access/revoke`, { smartAccessId, ownerId });
    };

    async acceptSmartAccess(smartAccessId: string, userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.post<void>(`/smart-access/accept`, { smartAccessId, userId });
    };

    async relinquishSmartAccess(smartAccessId: string, userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.post<void>(`/smart-access/relinquish`, { smartAccessId, userId });
    };

    async downloadAssetSmartAccess(assetId: string, userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.post<string>(`/smart-access/download`, { assetId, userId });
    };

    async declineSmartAccess(smartAccessId: string, userId: string) {
        await this.refreshToken();
        return this._peerdwebApi.post<void>(`/smart-access/decline`, { smartAccessId, userId });
    };

    async validateSmartAccessTxHash(txHash: string) {
        await this.refreshToken();
        return this._peerdwebApi.get<ValidateAssetResponse>(`/validation/smartaccess/${txHash}`);
    }
    /* #endregion */


    /* #region Analytics */
    async getTopDownloadedAssets(searchRequest: DateRangeSearchRequest) {
        await this.refreshToken();
        return this._peerdwebApi.get<AssetDownloads[]>(`/analytics/top-downloaded-assets/?${qs.stringify(searchRequest)}`);
    };

    /* #endregion */

    async latestActivity() {
        await this.refreshToken();
        return this._peerdwebApi.get<AssetSearchResponse>(`/assets/latest?pageSize=200`);
    }


}