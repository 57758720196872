import { FC } from 'react';
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import type { Theme } from '@mui/material';
import { Box, IconButton, Link, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { AccountButton } from '../account-button';
import { RouterLink } from 'components/router-link';
import { paths } from 'paths';
import { NotificationsButton } from '../notifications-button';
import { SearchButton } from '../search-button';
import { PeersButton } from '../peers-button';

const TOP_NAV_HEIGHT: number = 64;
const SIDE_NAV_WIDTH: number = 280;

interface TopNavProps {
    onMobileNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = (props) => {
    const { onMobileNavOpen, ...other } = props;
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(2px)',
                backgroundColor: '#ffffffdd',
                position: 'sticky',
                left: {
                    lg: `${SIDE_NAV_WIDTH}px`
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
                },
                zIndex: (theme) => theme.zIndex.appBar,
            }}
            {...other}>


            <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}
                sx={{ minHeight: TOP_NAV_HEIGHT, px: 2 }}>

                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-evenly"
                    spacing={2}
                >
                    {!lgUp && (
                        <IconButton onClick={onMobileNavOpen} sx={{ display: "flex", flexDirection: "column" }}>
                            <SvgIcon>
                                <Menu01Icon />
                            </SvgIcon>
                            <Typography variant='caption' sx={{ fontSize: 8 }}>
                                MENU
                            </Typography>
                        </IconButton>
                    )}

                    {/* <Box sx={{ pl: { xs: 0, sm: 3 } }}>
                        <SearchButton />
                    </Box> */}
                </Stack>
                {!lgUp &&
                    <>
                        <Link component={RouterLink} href={paths.index}>
                            <img src='/images/peerdweb-logo-long.png' alt='Peerdweb' style={{ width: smUp ? 180 : 130 }} />
                        </Link>
                    </>
                }
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    
                    {/* <PeersButton />
                    <NotificationsButton />  */}
                   
                    <AccountButton />
                </Stack>
            </Stack>
        </Box>
    );
};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func
};
