import { useTheme } from '@mui/material/styles';

export const Logo = () => {
    const theme = useTheme();
    const fillColor = theme.palette.primary.main;

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="100%" viewBox="0 0 391 391" enableBackground="new 0 0 391 391">
            <path fill="#fff" opacity="1.000000" stroke="none"
                d="
M263.000000,392.000000 
	C175.333359,392.000000 88.166718,392.000000 1.000058,392.000000 
	C1.000039,261.666718 1.000039,131.333466 1.000020,1.000146 
	C131.333237,1.000097 261.666473,1.000097 391.999756,1.000049 
	C391.999847,131.333191 391.999847,261.666382 391.999939,391.999786 
	C349.166656,392.000000 306.333344,392.000000 263.000000,392.000000 
M385.732849,131.556595 
	C385.576508,131.306534 385.351135,131.172501 384.525116,130.674576 
	C378.267761,111.659073 354.918610,106.607338 341.407776,119.358231 
	C340.900726,120.227425 340.393677,121.096611 339.493164,122.181358 
	C339.248108,122.367767 339.070770,122.604843 338.373474,123.279007 
	C332.986786,129.915405 333.496338,137.573486 334.720154,146.055038 
	C337.698395,156.757111 344.817993,163.291550 355.634949,164.754120 
	C366.319427,166.198776 375.543579,163.038818 382.047302,153.553391 
	C386.675354,146.803589 386.141632,139.418457 385.732849,131.556595 
M309.939270,26.418131 
	C304.052979,21.904736 297.312195,20.370764 290.068390,21.025660 
	C276.146179,22.284334 265.948639,34.689236 265.983521,45.781700 
	C266.043213,64.772789 266.000000,83.764198 266.000000,102.755478 
	C266.000000,105.369156 266.000000,107.982841 266.000000,111.000000 
	C263.708038,111.000000 261.910522,111.000000 260.113007,111.000000 
	C236.290619,111.000008 212.464325,110.775635 188.649094,111.188408 
	C183.857834,111.271454 178.553009,112.856300 174.449692,115.341545 
	C164.358597,121.453423 160.708618,130.900223 161.887985,142.744858 
	C163.149582,155.415466 176.223373,166.249908 187.434586,166.100143 
	C211.752396,165.775299 236.077560,166.000000 260.399719,166.000000 
	C262.166138,166.000000 263.932556,166.000000 266.000000,166.000000 
	C266.000000,168.419662 266.000000,170.226028 266.000000,172.032394 
	C266.000000,215.679001 266.009338,259.325623 265.991211,302.972229 
	C265.988007,310.640747 268.802765,317.210938 274.628845,321.964996 
	C283.803345,329.451355 294.277222,331.582886 305.178772,326.215454 
	C315.781586,320.995117 321.004120,312.015442 321.003235,300.099518 
	C320.997162,217.470810 320.953552,134.842056 321.060028,52.213486 
	C321.073029,42.131187 318.632965,33.419224 309.939270,26.418131 
M140.515656,223.000000 
	C139.182724,223.000000 137.849686,223.011414 136.516876,222.998184 
	C127.006706,222.903870 128.021072,223.981552 128.013763,214.375381 
	C127.981636,172.055389 128.062088,129.735168 127.952103,87.415436 
	C127.926819,77.686989 124.061264,69.588837 115.568680,64.389458 
	C106.004829,58.534225 96.121780,58.249081 86.293564,63.914524 
	C77.871010,68.769684 73.093285,76.194321 73.078369,85.835236 
	C72.946121,171.308151 73.008247,256.781372 72.989166,342.254486 
	C72.987625,349.160706 75.960587,354.940491 80.428604,359.695465 
	C87.974739,367.726349 101.264641,370.434326 110.781448,365.995453 
	C121.770805,360.869751 128.036835,352.345642 128.010132,339.786438 
	C127.970467,321.125732 128.138306,302.463104 127.902939,283.805267 
	C127.844688,279.187103 129.280258,277.865845 133.839981,277.907043 
	C156.497681,278.111938 179.158417,277.944916 201.817673,278.026733 
	C211.083572,278.060181 219.085541,275.535339 225.315720,268.236237 
	C233.076492,259.143951 233.819580,249.100891 229.615280,238.429291 
	C226.054611,229.391449 216.282990,223.017136 206.491043,223.007294 
	C184.831207,222.985535 163.171326,223.000198 140.515656,223.000000 
M59.015434,256.344360 
	C62.670860,244.274963 55.795681,230.739227 44.332195,226.080612 
	C23.900202,217.777283 5.874890,232.701385 6.924119,252.469986 
	C7.621179,265.603333 21.743910,278.648193 34.424709,276.946594 
	C46.509628,275.324921 55.428459,269.519104 59.015434,256.344360 
z"/>
            <path fill="#6262F8" opacity="1.000000" stroke="none"
                d="
M310.188110,26.686419 
	C318.632965,33.419224 321.073029,42.131187 321.060028,52.213486 
	C320.953552,134.842056 320.997162,217.470810 321.003235,300.099518 
	C321.004120,312.015442 315.781586,320.995117 305.178772,326.215454 
	C294.277222,331.582886 283.803345,329.451355 274.628845,321.964996 
	C268.802765,317.210938 265.988007,310.640747 265.991211,302.972229 
	C266.009338,259.325623 266.000000,215.679001 266.000000,172.032394 
	C266.000000,170.226028 266.000000,168.419662 266.000000,166.000000 
	C263.932556,166.000000 262.166138,166.000000 260.399719,166.000000 
	C236.077560,166.000000 211.752396,165.775299 187.434586,166.100143 
	C176.223373,166.249908 163.149582,155.415466 161.887985,142.744858 
	C160.708618,130.900223 164.358597,121.453423 174.449692,115.341545 
	C178.553009,112.856300 183.857834,111.271454 188.649094,111.188408 
	C212.464325,110.775635 236.290619,111.000008 260.113007,111.000000 
	C261.910522,111.000000 263.708038,111.000000 266.000000,111.000000 
	C266.000000,107.982841 266.000000,105.369156 266.000000,102.755478 
	C266.000000,83.764198 266.043213,64.772789 265.983521,45.781700 
	C265.948639,34.689236 276.146179,22.284334 290.068390,21.025660 
	C297.312195,20.370764 304.052979,21.904736 310.188110,26.686419 
z"/>
            <path fill="#22265D" opacity="1.000000" stroke="none"
                d="
M141.013565,223.000000 
	C163.171326,223.000198 184.831207,222.985535 206.491043,223.007294 
	C216.282990,223.017136 226.054611,229.391449 229.615280,238.429291 
	C233.819580,249.100891 233.076492,259.143951 225.315720,268.236237 
	C219.085541,275.535339 211.083572,278.060181 201.817673,278.026733 
	C179.158417,277.944916 156.497681,278.111938 133.839981,277.907043 
	C129.280258,277.865845 127.844688,279.187103 127.902939,283.805267 
	C128.138306,302.463104 127.970467,321.125732 128.010132,339.786438 
	C128.036835,352.345642 121.770805,360.869751 110.781448,365.995453 
	C101.264641,370.434326 87.974739,367.726349 80.428604,359.695465 
	C75.960587,354.940491 72.987625,349.160706 72.989166,342.254486 
	C73.008247,256.781372 72.946121,171.308151 73.078369,85.835236 
	C73.093285,76.194321 77.871010,68.769684 86.293564,63.914524 
	C96.121780,58.249081 106.004829,58.534225 115.568680,64.389458 
	C124.061264,69.588837 127.926819,77.686989 127.952103,87.415436 
	C128.062088,129.735168 127.981636,172.055389 128.013763,214.375381 
	C128.021072,223.981552 127.006706,222.903870 136.516876,222.998184 
	C137.849686,223.011414 139.182724,223.000000 141.013565,223.000000 
z"/>
            <path fill="#6262F8" opacity="1.000000" stroke="none"
                d="
M59.005768,256.764954 
	C55.428459,269.519104 46.509628,275.324921 34.424709,276.946594 
	C21.743910,278.648193 7.621179,265.603333 6.924119,252.469986 
	C5.874890,232.701385 23.900202,217.777283 44.332195,226.080612 
	C55.795681,230.739227 62.670860,244.274963 59.005768,256.764954 
z"/>
            <path fill="#22265D" opacity="1.000000" stroke="none"
                d="
M341.997192,119.158035 
	C354.918610,106.607338 378.267761,111.659073 384.909973,131.058228 
	C385.463013,131.626938 385.646515,131.795334 385.845337,131.947083 
	C386.141632,139.418457 386.675354,146.803589 382.047302,153.553391 
	C375.543579,163.038818 366.319427,166.198776 355.634949,164.754120 
	C344.817993,163.291550 337.698395,156.757111 334.909546,145.330261 
	C336.386322,137.367859 337.673737,130.130219 338.961121,122.892578 
	C339.070770,122.604843 339.248108,122.367767 339.994171,121.971245 
	C340.995850,120.893433 341.496521,120.025726 341.997192,119.158035 
z"/>
            <path fill="#22265D" opacity="1.000000" stroke="none"
                d="
M338.667297,123.085793 
	C337.673737,130.130219 336.386322,137.367859 334.826050,144.965942 
	C333.496338,137.573486 332.986786,129.915405 338.667297,123.085793 
z"/>
            <path fill="#22265D" opacity="1.000000" stroke="none"
                d="
M341.702484,119.258133 
	C341.496521,120.025726 340.995850,120.893433 340.190918,121.863464 
	C340.393677,121.096611 340.900726,120.227425 341.702484,119.258133 
z"/>
            <path fill="#22265D" opacity="1.000000" stroke="none"
                d="
M385.789093,131.751831 
	C385.646515,131.795334 385.463013,131.626938 385.175781,131.298187 
	C385.351135,131.172501 385.576508,131.306534 385.789093,131.751831 
z"/>
        </svg>
    );
};
