import { FC } from 'react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';

interface IProps {
    onAssetValueChange: (assetValue: string) => void;
    assetValue?: string;
}

export const AssetValueField: FC<IProps> = ({ onAssetValueChange, assetValue }) => {

    const handleChange = (event: SelectChangeEvent) => {
        onAssetValueChange(event.target.value as string);
    };

    return (
        <>
            <Stack direction="row" alignItems={'center'} spacing={1} sx={{ pt: 5 }}>
                <Typography variant="h6" sx={{ mb: 0, minWidth: 100 }}>
                    Asset Value
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={assetValue ?? ''}
                        sx={{ width: 200 }}
                        onChange={handleChange}
                    >
                        <MenuItem value={''}> &nbsp;</MenuItem>
                        <MenuItem value={'Critical'}>Critical</MenuItem>
                        <MenuItem value={'High'}>High</MenuItem>
                        <MenuItem value={'Medium'}>Medium</MenuItem>
                        <MenuItem value={'Low'}>Low</MenuItem>
                    </Select>
                </Stack>
            </Stack>
        </>
    );
};