import { FC } from 'react';
import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';

export const Footer: FC = () => {

    return (
        <Container component="footer" sx={{
            backgroundColor: '#ffffff',
            py: 3,
            borderTopColor: '#F0F0FF',
            borderTopStyle: 'solid',
            borderTopWidth: 3,
            mx: 0,
            width: '100%',
            maxWidth: '100% !important',
            flexGrow: 1
        }}>
            <Box sx={{ flexGrow: 1 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Box>
                            <img src='/images/peerdweb-logo-long.png' alt='Peerdweb' style={{ maxHeight: '64px', maxWidth: '104px', marginTop: '5px' }} />
                        </Box>
                        <Typography variant="subtitle2">
                            Secure, manage, innovate.<br />
                            <Link id='mailto-link' href="mailto:support@peerdweb.io">support@peerdweb.io</Link>
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" direction="column" justifyContent='center' spacing={1}
                        sx={{ px: 2, textAlign: "center" }}>
                        <Box>
                            <Typography variant="caption">
                                Copyright © 2024 Peerdweb.io | All Rights Reserved
                            </Typography>
                        </Box>

                    </Stack>
                </Container>
            </Box>
        </Container >
    );
};

