import { useMounted } from 'hooks/use-mounted';
import { useCallback, useEffect, useState } from 'react';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { OrganisationsResponse } from 'types/organisation/organisation';
import { UserSearchResponse } from 'types/search/user-search';

export const useSuperUserOrganisationsStore = () => {
    const isMounted = useMounted();
    const [allOrganisations, setAllOrganisations] = useState<OrganisationsResponse>({
        organisations: [],
        total: 0
    });
    const peerdwebService = new PeerdwebService();

    const getAllOrganisations = useCallback(async () => {
        try {
            const response = await peerdwebService.superAdminGetOrganisations();
            if (isMounted()) {
                setAllOrganisations({ organisations: response.data.organisations, total: response.data.total });
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        getAllOrganisations();
    }, []);

    return {
        ...allOrganisations
    };
};