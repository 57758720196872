import AlignLeft02Icon from 'icons/untitled-ui/duocolor/align-left-02';
import BarChartSquare02Icon from 'icons/untitled-ui/duocolor/bar-chart-square-02';
import Building04Icon from 'icons/untitled-ui/duocolor/building-04';
import CalendarIcon from 'icons/untitled-ui/duocolor/calendar';
import CheckDone01Icon from 'icons/untitled-ui/duocolor/check-done-01';
import CreditCard01Icon from 'icons/untitled-ui/duocolor/credit-card-01';
import CurrencyBitcoinCircleIcon from 'icons/untitled-ui/duocolor/currency-bitcoin-circle';
import File01Icon from 'icons/untitled-ui/duocolor/file-01';
import GraduationHat01Icon from 'icons/untitled-ui/duocolor/graduation-hat-01';
import HomeSmileIcon from 'icons/untitled-ui/duocolor/home-smile';
import LayoutAlt02Icon from 'icons/untitled-ui/duocolor/layout-alt-02';
import LineChartUp04Icon from 'icons/untitled-ui/duocolor/line-chart-up-04';
import Lock01Icon from 'icons/untitled-ui/duocolor/lock-01';
import LogOut01Icon from 'icons/untitled-ui/duocolor/log-out-01';
import Mail03Icon from 'icons/untitled-ui/duocolor/mail-03';
import Mail04Icon from 'icons/untitled-ui/duocolor/mail-04';
import MessageChatSquareIcon from 'icons/untitled-ui/duocolor/message-chat-square';
import ReceiptCheckIcon from 'icons/untitled-ui/duocolor/receipt-check';
import Share07Icon from 'icons/untitled-ui/duocolor/share-07';
import ShoppingBag03Icon from 'icons/untitled-ui/duocolor/shopping-bag-03';
import ShoppingCart01Icon from 'icons/untitled-ui/duocolor/shopping-cart-01';
import Truck01Icon from 'icons/untitled-ui/duocolor/truck-01';
import Upload04Icon from 'icons/untitled-ui/duocolor/upload-04';
import Users03Icon from 'icons/untitled-ui/duocolor/users-03';
import XSquareIcon from 'icons/untitled-ui/duocolor/x-square';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import {
    Box,
    Button,
    Container,
    Stack,
    SvgIcon,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import { Seo } from 'components/seo';
import { usePageView } from 'hooks/use-page-view';
import { useSettings } from 'hooks/use-settings';
import type { Page as PageType } from 'types/page';

const now = new Date();

const IconsPage: PageType = () => {
    const settings = useSettings();

    usePageView();

    return (
        <>
            <Seo title="Peerdweb: Icons" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth={settings.stretch ? false : 'xl'}>
                    <Grid container disableEqualOverflow spacing={{ xs: 3, lg: 4 }}>
                        <Grid xs={12}>
                            <Stack direction="row" justifyContent="space-between" spacing={4}>
                                <div>
                                    <Typography variant="h4">
                                        ICONS
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid xs={12}>
                            <img src="/assets/logos/logo-amplify.svg" width={64} />
                            <img src="/assets/logos/logo-angular.svg" width={64} />
                            <img src="/assets/logos/logo-auth0.svg" width={64} />
                            <img src="/assets/logos/logo-bitcoin.svg" width={64} />
                            <img src="/assets/logos/logo-bnb.svg" width={64} />
                            <img src="/assets/logos/logo-eth.svg" width={64} />
                            <img src="/assets/logos/logo-figma.svg" width={64} />
                            <img src="/assets/logos/logo-firebase.svg" width={64} />
                            <img src="/assets/logos/logo-google.svg" width={64} />
                            <img src="/assets/logos/logo-html.svg" width={64} />
                            <img src="/assets/logos/logo-javascript.svg" width={64} />
                            <img src="/assets/logos/logo-jwt.svg" width={64} />
                            <img src="/assets/logos/logo-mastercard.svg" width={64} />
                            <img src="/assets/logos/logo-react-js.svg" width={64} />
                            <img src="/assets/logos/logo-sketch.svg" width={64} />
                            <img src="/assets/logos/logo-typescript.svg" width={64} />
                            <img src="/assets/logos/logo-visa.svg" width={64} />
                            <img src="/assets/logos/logo-vue-js.svg" width={64} />
                        </Grid>
                        <Grid xs={12}>
                            <img src="/assets/iconly/iconly-glass-chart.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-discount.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-info.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-paper.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-shield.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-tick.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-video.svg" width={64} />
                            <img src="/assets/iconly/iconly-glass-volume.svg" width={64} />
                            <img src="/assets/icons/icon-folder.svg" width={64} />
                            <img src="/assets/icons/icon-jpg.svg" width={64} />
                            <img src="/assets/icons/icon-mp4.svg" width={64} />
                            <img src="/assets/icons/icon-other.svg" width={64} />
                            <img src="/assets/icons/icon-pdf.svg" width={64} />
                            <img src="/assets/icons/icon-png.svg" width={64} />
                            <img src="/assets/icons/icon-svg.svg" width={64} />
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='action' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='disabled' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='error' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='info' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='primary' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='secondary' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='success' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <SvgIcon color='warning' fontSize="large"><AlignLeft02Icon /></SvgIcon>
                            <br />
                            <Button variant="contained" startIcon={(<SvgIcon><AlignLeft02Icon /></SvgIcon>)}>AlignLeft02Icon</Button>
                            <Button startIcon={(<SvgIcon><AlignLeft02Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><AlignLeft02Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><AlignLeft02Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><BarChartSquare02Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><BarChartSquare02Icon /></SvgIcon>)}>BarChartSquare02Icon</Button>
                            <Button startIcon={(<SvgIcon><BarChartSquare02Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><BarChartSquare02Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><BarChartSquare02Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Building04Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Building04Icon /></SvgIcon>)}>Building04Icon</Button>
                            <Button startIcon={(<SvgIcon><Building04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Building04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Building04Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><CalendarIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><CalendarIcon /></SvgIcon>)}>CalendarIcon</Button>
                            <Button startIcon={(<SvgIcon><CalendarIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><CalendarIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><CalendarIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><CreditCard01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><CreditCard01Icon /></SvgIcon>)}>CreditCard01Icon</Button>
                            <Button startIcon={(<SvgIcon><CreditCard01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><CreditCard01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><CreditCard01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><CheckDone01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><CheckDone01Icon /></SvgIcon>)}>CheckDone01Icon</Button>
                            <Button startIcon={(<SvgIcon><CheckDone01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><CheckDone01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><CheckDone01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><CurrencyBitcoinCircleIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><CurrencyBitcoinCircleIcon /></SvgIcon>)}>CurrencyBitcoinCircleIcon</Button>
                            <Button startIcon={(<SvgIcon><CurrencyBitcoinCircleIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><CurrencyBitcoinCircleIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><CurrencyBitcoinCircleIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><File01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><File01Icon /></SvgIcon>)}>File01Icon</Button>
                            <Button startIcon={(<SvgIcon><File01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><File01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><File01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><GraduationHat01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><GraduationHat01Icon /></SvgIcon>)}>GraduationHat01Icon</Button>
                            <Button startIcon={(<SvgIcon><GraduationHat01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><GraduationHat01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><GraduationHat01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><HomeSmileIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><HomeSmileIcon /></SvgIcon>)}>HomeSmileIcon</Button>
                            <Button startIcon={(<SvgIcon><HomeSmileIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><HomeSmileIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><HomeSmileIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><LayoutAlt02Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><LayoutAlt02Icon /></SvgIcon>)}>LayoutAlt02Icon</Button>
                            <Button startIcon={(<SvgIcon><LayoutAlt02Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><LayoutAlt02Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><LayoutAlt02Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><LineChartUp04Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><LineChartUp04Icon /></SvgIcon>)}>LineChartUp04Icon</Button>
                            <Button startIcon={(<SvgIcon><LineChartUp04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><LineChartUp04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><LineChartUp04Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Lock01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Lock01Icon /></SvgIcon>)}>Lock01Icon</Button>
                            <Button startIcon={(<SvgIcon><Lock01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Lock01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Lock01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><LogOut01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><LogOut01Icon /></SvgIcon>)}>LogOut01Icon</Button>
                            <Button startIcon={(<SvgIcon><LogOut01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><LogOut01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><LogOut01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Mail03Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Mail03Icon /></SvgIcon>)}>Mail03Icon</Button>
                            <Button startIcon={(<SvgIcon><Mail03Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Mail03Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Mail03Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Mail04Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Mail04Icon /></SvgIcon>)}>Mail04Icon</Button>
                            <Button startIcon={(<SvgIcon><Mail04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Mail04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Mail04Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><MessageChatSquareIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><MessageChatSquareIcon /></SvgIcon>)}>MessageChatSquareIcon</Button>
                            <Button startIcon={(<SvgIcon><MessageChatSquareIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><MessageChatSquareIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><MessageChatSquareIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><PlusIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)}>PlusIcon</Button>
                            <Button startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><PlusIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><ReceiptCheckIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><ReceiptCheckIcon /></SvgIcon>)}>ReceiptCheckIcon</Button>
                            <Button startIcon={(<SvgIcon><ReceiptCheckIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><ReceiptCheckIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><ReceiptCheckIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Share07Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Share07Icon /></SvgIcon>)}>Share07Icon</Button>
                            <Button startIcon={(<SvgIcon><Share07Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Share07Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Share07Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><ShoppingBag03Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><ShoppingBag03Icon /></SvgIcon>)}>ShoppingBag03Icon</Button>
                            <Button startIcon={(<SvgIcon><ShoppingBag03Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><ShoppingBag03Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><ShoppingBag03Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><ShoppingCart01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><ShoppingCart01Icon /></SvgIcon>)}>ShoppingCart01Icon</Button>
                            <Button startIcon={(<SvgIcon><ShoppingCart01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><ShoppingCart01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><ShoppingCart01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Truck01Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Truck01Icon /></SvgIcon>)}>Truck01Icon</Button>
                            <Button startIcon={(<SvgIcon><Truck01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Truck01Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Truck01Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Upload04Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Upload04Icon /></SvgIcon>)}>Upload04Icon</Button>
                            <Button startIcon={(<SvgIcon><Upload04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Upload04Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Upload04Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><Users03Icon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><Users03Icon /></SvgIcon>)}>Users03Icon</Button>
                            <Button startIcon={(<SvgIcon><Users03Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><Users03Icon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><Users03Icon /></SvgIcon>)}>Text</Button>
                        </Grid>
                        <Grid xs={4}>
                            <SvgIcon fontSize="large"><XSquareIcon /></SvgIcon><br />
                            <Button variant="contained" startIcon={(<SvgIcon><XSquareIcon /></SvgIcon>)}>XSquareIcon</Button>
                            <Button startIcon={(<SvgIcon><XSquareIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='outlined' startIcon={(<SvgIcon><XSquareIcon /></SvgIcon>)}>Text</Button>
                            <Button variant='text' startIcon={(<SvgIcon><XSquareIcon /></SvgIcon>)}>Text</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default IconsPage;
