import { useEffect, type FC } from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import { SxProps } from '@mui/system';
import { Avatar, Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { usePopover } from 'hooks/use-popover';
import { OrganisationPopover } from './organisation-popover';
import { useAuth } from 'hooks/use-auth';
import GroupsIcon from '@mui/icons-material/Groups';
import { PeerdwebColours } from 'theme/peerdweb-colours';
import { displayInitials } from 'utils/pretty-pretty';
import { useRouter } from 'hooks/use-router';

const trimDisplayName = (name: string) => {
    if (name.length > 15) {
        return name.substring(0, 15) + '…';
    }
    return name;
}

interface IProps {
    sx?: SxProps;
}

export const OrganisationSwitch: FC<IProps> = (props) => {
    const popover = usePopover<HTMLButtonElement>();
    const { organisations, organisation, switchOrganisation } = useAuth();
    const router = useRouter();
    const switchEnabled = organisations!.length > 1;

    console.log('OrganisationSwitch', organisations, organisation);
    const handleOrganisationChange = (organisationId: string) => {
        switchOrganisation(organisationId);
        popover.handleClose();
        router.push('/');
    }

    return (
        <>
            {switchEnabled && (
                <Stack alignItems="center" direction="row" spacing={2} {...props} sx={{ cursor: 'pointer' }} onClick={popover.handleOpen}>
                    <Avatar {...displayInitials(organisation?.name ?? 'PW')} variant="rounded" />
                    <Box sx={{ flexGrow: 1 }} >
                        <Typography color="neutral.400" variant="body2" sx={{ fontSize: '12px !important' }}>
                            Organisation
                        </Typography>
                        <Typography color="inherit" variant="h6" sx={{ fontSize: '14px !important' }}>
                            {trimDisplayName(organisation?.name ?? '')}
                        </Typography>
                    </Box>
                    <IconButton size='large' onClick={popover.handleOpen} ref={popover.anchorRef}>
                        <SvgIcon sx={{ fontSize: 16 }}>
                            <ChevronDownIcon />
                        </SvgIcon>
                    </IconButton>
                </Stack>
            )}
            <OrganisationPopover
                anchorEl={popover.anchorRef.current}
                onChange={handleOrganisationChange}
                onClose={popover.handleClose}
                open={popover.open}
                organisations={organisations ?? []}
            />


            {!switchEnabled && (
                <Stack alignItems="center" direction="row" spacing={2} {...props} >
                    <Avatar {...displayInitials(organisation?.name ?? 'PW')} variant="rounded" />
                    <Box sx={{ flexGrow: 1 }} >
                        <Typography color="neutral.400" variant="body2" sx={{ fontSize: '12px !important' }}>
                            Organisation
                        </Typography>
                        <Typography color="inherit" variant="h6" sx={{ fontSize: '14px !important' }}>
                            {trimDisplayName(organisation?.name ?? '')}
                        </Typography>
                    </Box>
                </Stack>
            )}

        </>
    );
};

OrganisationSwitch.propTypes = {
    // @ts-ignore
    sx: PropTypes.object
};
