import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Asset } from 'types/assets/asset';
import { Avatar, Badge, Link, SvgIcon } from '@mui/material';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { styled } from '@mui/material/styles';
import { paths } from 'paths';
import { useRouter } from 'hooks/use-router';
import { ViewAssetsSearch } from './view-assets-search';
import { AssetStoreSearchFilters } from 'hooks/assets/useAssetStore';
import { useAuth } from 'hooks/use-auth';

interface Data {
    id: string;
    name: string;
    project: string;
    subject: string;
    format: string;
    product: string;
    status: string;
    createdAt: string;
    owner: string;
    ownerAvatar: string;
    ownerId: string;
    assetValue: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: Order) {
    if (a[orderBy] == '' || b[orderBy] == '') {
        if (a[orderBy] == b[orderBy]) return 0;
        else {
            if (order == "asc") {
                if (a[orderBy] == '') return -1;
                else return 1;
            } else {
                if (a[orderBy] == '') return 1;
                else return -1;
            }
        }
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, order)
        : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
    hiddenInSlimMode?: boolean;
}

const headCells: readonly HeadCell[] = [
    { id: 'name', label: 'Asset' },
    { id: 'project', label: 'Project', hiddenInSlimMode: true },
    { id: 'assetValue', label: 'Value', hiddenInSlimMode: true },
    { id: 'subject', label: 'Subject', hiddenInSlimMode: true },
    { id: 'format', label: 'Format', hiddenInSlimMode: true },
    { id: 'product', label: 'Product', hiddenInSlimMode: true },
    { id: 'status', label: 'Status' },
    { id: 'createdAt', label: 'Date Protected' },
    { id: 'owner', label: 'Owner' },
];

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

interface EnhancedTableProps {
    countOfSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    slimMode: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, countOfSelected, rowCount, onRequestSort, slimMode } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {!slimMode &&
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={countOfSelected > 0 && countOfSelected < rowCount}
                            checked={rowCount > 0 && countOfSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'Select all', }}
                        />
                    </TableCell>
                }
                {/* thought about coding the heads, but we're *looking* at dynamically choosing columns at some point */}
                {headCells.map((headCell) => {
                    if (headCell.hiddenInSlimMode && slimMode) {
                        return null; // Don't render the cell
                    }
                    return (
                        <TableCell
                            key={headCell.id} align={'left'} padding={'none'} sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ minWidth: (headCell.id === 'name' ? 200 : 0) }} >
                            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    assets?: Asset[];
    countOfSelected: number;
    onFiltersChange: (filters: AssetStoreSearchFilters) => void
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { assets, countOfSelected, onFiltersChange } = props;

    return (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, ...(countOfSelected > 0 && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }) }}>
            {countOfSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {countOfSelected} selected
                </Typography>
            ) : (
                <>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                        <ViewAssetsSearch onFiltersChange={onFiltersChange} assets={assets} />
                    </Typography>
                </>
            )}

            {countOfSelected > 0 &&
                <Tooltip title="Actions">
                    <IconButton>
                        <MoreHorizIcon />
                    </IconButton>
                </Tooltip>
            }
        </Toolbar>
    );
}

function EnhancedTableRow(props: { row: ReturnType<typeof createData>, handleClick: any, isSelected: any, index: number, slimMode: boolean }) {
    const { row, handleClick, isSelected, index, slimMode } = props;
    const { peerdwebUser } = useAuth();

    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const router = useRouter();

    return (
        <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >

            {!slimMode &&
                <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} onClick={(event) => handleClick(event, row.id)} />
                </TableCell>
            }
            <TableCell id={labelId} scope="row" padding="none" sx={{ fontSize: slimMode ? 14 : 14, pl: 2, cursor: 'pointer' }}>
                {/* onClick={() => router.push(paths.assets.view(row.id as string))} */}
                <Link href={`/asset/view/${row.id}`}>
                    {row.name}
                </Link>
            </TableCell>
            {!slimMode &&
                <>
                    <TableCell sx={{ minWidth: 200 }}>{row.project}</TableCell>
                    <TableCell sx={{ minWidth: 200 }}>{row.assetValue}</TableCell>
                    <TableCell sx={{ minWidth: 200 }}>{row.subject}</TableCell>
                    <TableCell sx={{ minWidth: 200 }}>{row.format}</TableCell>
                    <TableCell sx={{ minWidth: 200 }}>{row.product}</TableCell>
                </>
            }
            <TableCell sx={{ minWidth: 200 }}>{row.status}</TableCell>
            <TableCell sx={{ minWidth: 200 }}>
                {new Date(row.createdAt).toLocaleString('en-GB').split(',')[0] || ''}
            </TableCell>
            <TableCell sx={{ minWidth: 200, borderLeft: '1px solid #F8F9FA', pl: 2 }}>

                {row.ownerId === peerdwebUser?._id ?
                    (<StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                        <Avatar sx={{ height: 32, width: 32 }} src={row.ownerAvatar ?? ''} title={row.owner ?? 'Peer'}>
                            <SvgIcon>
                                <User01Icon />
                            </SvgIcon>
                        </Avatar>
                    </StyledBadge>)
                    :
                    (<Avatar sx={{ height: 32, width: 32 }} src={row.ownerAvatar ?? ''} title={row.owner ?? 'Peer'}>
                        <SvgIcon>
                            <User01Icon />
                        </SvgIcon>
                    </Avatar>)
                }
            </TableCell>
        </TableRow>
    );
}


function createData(
    id: string,
    name: string,
    project: string,
    format: string,
    subject: string,
    product: string,
): Data {
    return {
        id,
        name,
        project,
        format,
        subject,
        product,
        status: 'Active',
        createdAt: '12/12/2021',
        owner: 'John Doe',
        ownerAvatar: 'https://randomuser.me/api/portraits',
        ownerId: '',
        assetValue: ''
    };
}


interface IProps {
    assets?: Asset[];
    onFiltersChange: (filters: AssetStoreSearchFilters) => void;
    slimMode?: boolean;
}

export const ViewAssetsEnhancedTable: React.FC<IProps> = ({ assets, onFiltersChange, slimMode = false }) => {

    console.log('ViewAssetsEnhancedTable', assets);
    const initialRows = assets ? assets.map(asset => ({
        id: asset._id || '',
        name: asset.headline,
        project: asset.project?.name || '',
        format: asset.format || '',
        subject: asset.subject || '',
        product: asset.product || '',
        status: asset.status || '',
        createdAt: new Date(asset.createdAt).toISOString() || '',
        owner: asset.owner?.displayName || '',
        ownerAvatar: asset.owner?.imageUrl || 'https://randomuser.me/api/portraits',
        ownerId: asset.owner?._id ?? '',
        assetValue: asset.assetValue || ''
    })) : [];
    const [rows, setRows] = React.useState<Data[]>(initialRows);

    React.useEffect(() => {
        if (assets) {
            const updatedRows = assets.map(asset => ({
                id: asset._id || '',
                name: asset.headline,
                project: asset.project?.name || '',
                format: asset.format || '',
                subject: asset.subject || '',
                product: asset.product || '',
                status: asset.status || '',
                createdAt: new Date(asset.createdAt).toISOString() || '',
                owner: asset.owner?.displayName || '',
                ownerAvatar: asset.owner?.imageUrl || 'https://randomuser.me/api/portraits',
                ownerId: asset.owner?._id ?? '',
                assetValue: asset.assetValue || ''
            }));
            setRows(updatedRows);
        }
    }, [assets]);


    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleCheckClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        try {
            document.querySelector('.MuiTableContainer-root')!.scrollTop = 0;
        } catch (error) {
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        try {
            document.querySelector('.MuiTableContainer-root')!.scrollTop = 0;
        } catch (error) {
        }
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {!slimMode &&
                    <EnhancedTableToolbar countOfSelected={selected.length} onFiltersChange={onFiltersChange} assets={assets} />
                }
                <TableContainer sx={{ width: "100%", height: 480, overflowX: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="asset-list" size='small'>

                        <EnhancedTableHead
                            countOfSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            slimMode={slimMode}
                        />
                        {rows.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Typography variant="body1" align="center">
                                            No assets found
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {visibleRows.map((row, index) => (
                                    <EnhancedTableRow key={row.id} row={row} handleClick={handleCheckClick} index={index} isSelected={isSelected} slimMode={slimMode} />
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: (49) * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
