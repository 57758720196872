import { FC, ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    open: boolean,
    onConfirm: () => void,
    onCancel: () => void,
    confirmText: string,
    children?: ReactNode
}
export const SmartAccessDialog: FC<IProps> = ({ open, onConfirm, onCancel, children, confirmText }) => {

    return (
        <Dialog open={open} onClose={onCancel} scroll='paper' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <IconButton aria-label="close" onClick={onCancel} sx={{ position: 'absolute', right: 8, top: 8}}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    <Stack spacing={1}>
                        {children}
                    </Stack>
                </DialogContentText>
                <DialogActions>
                    <Button onClick={onConfirm} variant="contained">
                        {confirmText}
                    </Button>
                </DialogActions>
            </DialogContent >
        </Dialog >
    );
};

