import { PeerdwebColours } from "theme/peerdweb-colours";

export const toPrettyBlockchainName = (name: string) => {
    name = name.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
    return name.replaceAll('-', ' ');
}

export const toPrettyDateTime = (timestamp: Date) => {
    return `${timestamp?.toDateString()} at ${timestamp?.toLocaleTimeString()}`;
}

export const displayInitials = (name: string) => {
    let initials = 'PW';
    if (name.split(' ').length > 1) {
        initials = name.split(' ')[0][0] + name.split(' ')[1][0];
    } else if (name.length > 0) {
        initials = name.charAt(0);
    }
    return {
        sx: {
            backgroundColor: PeerdwebColours.Navy,
            '--Avatar-size': '32px',
            color: PeerdwebColours.Teal3
        },
        children: `${initials.toUpperCase()}`,
    };
}

export const toPrettyUserAccessStatus = (status?: string) => {
    return status === 'invite' ? 'Invited'
        : status === 'revoke' ? 'Revoked'
            : status === 'relinquish' ? 'Relinquished'
                : status === 'accept' ? 'Accepted'
                    : status === 'decline' ? 'Declined'
                        : '';
}

