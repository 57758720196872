import { FC } from 'react';
import { Chip, Link, Typography } from '@mui/material';
import { SmartAccessControlPanel } from './smart-access-control-panel';
import { Asset } from 'types/assets/asset';
import { useAuth } from 'hooks/use-auth';

interface IProps {
    asset?: Asset;
}

export const SmartAccess: FC<IProps> = ({ asset }) => {
    const { getSubscriptionLevel } = useAuth();
    const showSmartAccess = getSubscriptionLevel() === 'ENTERPRISE';

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Smart Access Control
            </Typography>

            {!showSmartAccess &&
                <>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Smart Access allows you to control access to your assets and invite others to collaborate.<br />
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Smart Access is available to Enterprise subscription users.  Visit the <Link href='/credits'>Billing</Link> page to upgrade.
                    </Typography>
                </>
            }

            {showSmartAccess &&
                <SmartAccessControlPanel assetId={asset?._id} headline={asset?.headline} />
            }

        </>
    );
};

