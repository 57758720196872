import { FC, useState } from 'react';
import { Button, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Project, UpdateProjectRequest, UpdateProjectValues } from 'types/project/project';
import { updateProjectValidationSchema } from 'validation/projects/update-project-validation-schema';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { useParams } from 'react-router';
import { useAuth } from 'hooks/use-auth';
import { toast } from 'react-hot-toast';
import { useRouter } from 'hooks/use-router';
import { paths } from 'paths';
import { useLogger } from 'hooks/use-logger';

interface IProps {
    project: Project
}

export const UpdateProject: FC<IProps> = ({ project }) => {
    const [loading, setLoading] = useState(false);
    const peerdwebService = new PeerdwebService();
    const { peerdwebUser, authUser } = useAuth();
    const router = useRouter();
    const [log] = useLogger();

    const initialValues: UpdateProjectValues = {
        name: project.name
    };

    const updateProject = async (projectValues: UpdateProjectValues) => {
        if (peerdwebUser == null)
            throw new Error("User is not logged in");

        var updateProjectRequest: UpdateProjectRequest = {
            _id: project._id ?? '',
            name: projectValues.name,
        };

        await peerdwebService.updateProject(updateProjectRequest);
    }

    const formik = useFormik({
        initialValues,
        validationSchema: updateProjectValidationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            setLoading(true);
            updateProject(values);
            toast.success("Project updated successfully", { icon: "✅" });
            log(authUser?.email ?? '', "ACTION // updated project " + project.name, "Warning", { projectId: project._id });
            router.push(paths.projects.list);
            setLoading(false);
        }
    });

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Update Project
            </Typography>

            {/* <Formik initialValues={initialValues} validationSchema={createAssetValidationSchema} onSubmit={handleSubmit}> */}
            <form noValidate onSubmit={formik.handleSubmit}>
                <TextField
                    error={!!(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label="Name *"
                    {...formik.getFieldProps("name")}
                    sx={{ backgroundColor: '#ffffff' }}
                />


                <Stack direction={{ sx: "column", md: "row" }} sx={{ gap: 2, mt: 5 }}>
                    <Button endIcon={(<SvgIcon></SvgIcon>)} type="submit" variant="contained" disabled={loading} sx={{ minWidth: 200 }}>
                        Save
                    </Button>
                </Stack>
            </form>


        </>
    );
};

