import { Box, Card, Container, Divider, Stack, Typography } from "@mui/material";
import { Seo } from "components/seo";
import { usePageView } from "hooks/use-page-view";
import type { Page as PageType } from "types/page";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from "react";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { DateRangeSearchRequest } from "types/analytics/date-range-search-request";
import { addDays, formatISO } from "date-fns";
import { Chart } from "components/analytics/chart";
import { ApexOptions } from "apexcharts";
import { PeerdwebColours } from "theme/peerdweb-colours";

const AnalyticsPage: PageType = () => {
    usePageView();

    const peerdwebService = new PeerdwebService();
    const [topDownloadedAssetsSeries, setTopDownloadedAssetsSeries] = useState<ApexAxisChartSeries>();

    const today = new Date();
    const defaultEndDate = new Date(formatISO(today, { representation: 'date' }));

    const sevenDaysAgo = addDays(today, -6);
    const defaultStartDate = new Date(formatISO(sevenDaysAgo, { representation: 'date' }));

    const [dateRange, setDateRange] = useState<DateRangeSearchRequest>({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });

    const getTopDownloadedAssets = async () => {
        const results = await peerdwebService.getTopDownloadedAssets(dateRange);

        const series: ApexAxisChartSeries = [
            {
                name: "Downloads",
                data: results.data.map((assetDownloads): { x: any, y: any } => {
                    return {
                        x: assetDownloads.asset,
                        y: assetDownloads.downloads,
                    }
                }),
            }
        ]

        setTopDownloadedAssetsSeries(series);
    };

    useEffect(() => {
        getTopDownloadedAssets();
    }, [dateRange]);

    const chartOptions: ApexOptions = {
        tooltip: {
            enabled: false,
        },
        chart: {
            background: "transparent",
            animations: {
                enabled: true,
                easing: "easein",
                speed: 400,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: false,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        colors: [
            PeerdwebColours.Navy,
            PeerdwebColours.Navy1,
            PeerdwebColours.Navy2,
            PeerdwebColours.Navy3,
        ],
        fill: {
            opacity: 1,
            type: "solid",
        },
        grid: {
            show: false,
        },
        title: {
            text: "Most Downloaded Assets",
        },
        xaxis: {
            labels: {
                show: false,
            }
        },
    };

    return (
        <>
            <Seo title="Peerdweb: Analytics" />
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Stack spacing={3} sx={{ mb: 3 }}>
                        <Stack spacing={1}>
                            <Typography variant="h4">Analytics</Typography>
                        </Stack>

                        <Divider />

                        <Card>
                            <Stack alignItems="center" direction='row' spacing={3} sx={{ px: 4, py: 3 }}>
                                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            format="dd/MM/yyyy"
                                            label="Start"
                                            value={dateRange.startDate}
                                            onChange={(newValue) => {
                                                setDateRange({
                                                    ...dateRange,
                                                    startDate: newValue as Date,
                                                });
                                            }}
                                        />

                                        <DatePicker
                                            format="dd/MM/yyyy"
                                            label="End"
                                            value={dateRange.endDate}
                                            onChange={(newValue) => {
                                                setDateRange({
                                                    ...dateRange,
                                                    endDate: newValue as Date,
                                                });
                                            }}
                                        />

                                    </LocalizationProvider>
                                </Box>

                            </Stack>
                        </Card>

                        {topDownloadedAssetsSeries && <Chart height={320} width={"100%"} options={chartOptions} series={topDownloadedAssetsSeries} type="bar" />}
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default AnalyticsPage;
