import type { FC } from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import { Box, Button, Chip, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, SvgIcon, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { useAuth } from 'hooks/use-auth';
import { useRouter } from 'hooks/use-router';
import { paths } from 'paths';
import { Issuer } from 'utils/auth';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

interface IProps {
    anchorEl: null | Element;
    onClose?: () => void;
    open?: boolean;
}

export const AccountPopover: FC<IProps> = (props) => {
    const { anchorEl, onClose, open, ...other } = props;
    const router = useRouter();
    const { authUser, peerdwebUser, getSubscriptionLevel, ...auth } = useAuth();
    const hostname = typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '';

    const handleLogout = useCallback(
        async (): Promise<void> => {
            try {
                onClose?.();

                switch (auth.issuer) {
                    case Issuer.Firebase: {
                        await auth.signOut();
                        toast.success('Signed out');
                        break;
                    }

                    default: {
                        console.warn('Using an unknown Auth Issuer, did not log out');
                    }
                }

                window.location.href = 'https://peerdweb.io';
            } catch (err) {
                console.error(err);
                toast.error('Something went wrong!');
            }
        },
        [auth, router, onClose]
    );

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 240 } }}
            {...other}
        >
            <Box sx={{ p: 2 }}>
                <Typography variant="body1">
                    {peerdwebUser?.displayName}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                    {peerdwebUser?.email}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ pt: 1 }}>
                <ListItemButton component={RouterLink} href={paths.account} onClick={onClose} sx={{ borderRadius: 1, px: 1, py: 0.5 }}>
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <ManageAccountsIcon />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={(<Typography variant="body1">Account Settings</Typography>)} />
                </ListItemButton>
            </Box>
            <Box sx={{ pb: 1 }}>
                <ListItemButton component={RouterLink} href={paths.organisation} onClick={onClose} sx={{ borderRadius: 1, px: 1, py: 0.5 }}>
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <GroupsIcon />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={(<Typography variant="body1">Organisation Settings</Typography>)} />
                </ListItemButton>
            </Box>

            {peerdwebUser?.superAdmin && (
                <Box sx={{ pb: 1 }}>
                    <ListItemButton component={RouterLink} href={paths.superAdmin.list} onClick={onClose} sx={{ borderRadius: 1, px: 1, py: 0.5 }}>
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <BadgeTwoToneIcon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary={(<Typography variant="body1">Super Admin View</Typography>)} />
                    </ListItemButton>
                </Box>
            )}

            {hostname.includes('localhost') &&
                <>
                    <Divider />
                    <Box sx={{ my: 2 }}>
                        <ListItem sx={{ borderRadius: 1, px: 1, py: 0.5 }}>
                            <Chip label={`Environment: Localhost`} color="warning" size="medium" sx={{ flexGrow: 0 }} />
                        </ListItem>
                        <ListItem sx={{ borderRadius: 1, px: 1, py: 0.5 }}>
                            <Chip label={`Org Sub Plan: ${getSubscriptionLevel()}`} color="warning" size="medium" sx={{ flexGrow: 0 }} />
                        </ListItem>
                    </Box>
                </>
            }

            <Divider sx={{ my: '0 !important' }} />

            <Box sx={{ display: 'flex', p: 1, justifyContent: 'center' }}>
                <Button color="inherit" onClick={handleLogout} size="small">
                    Logout
                </Button>
            </Box>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
