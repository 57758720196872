import { useCallback, useEffect, useState } from 'react';
import { useMounted } from 'hooks/use-mounted';
import { useAuth } from 'hooks/use-auth';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { SmartAccessActivityLogResponse, SmartAccessActivityLog } from 'types/smart-access/smart-access-activity';

export const useSmartAccessActivityLogStore = (assetId: string) => {
    const isMounted = useMounted();
    const [state, setState] = useState<SmartAccessActivityLogResponse>({
        activityLogs: [],
    });
    const auth = useAuth();
    const peerdwebService = new PeerdwebService();

    const getSmartAccessActivityLog = useCallback(async (assetId: string) => {
        try {
            const response = await peerdwebService.getSmartAccessActivityLogByAssetId(assetId);
            if (isMounted()) {
                setState({ activityLogs: response.data as SmartAccessActivityLog[] });
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMounted]);

    useEffect(() => {
        getSmartAccessActivityLog(assetId);
    }, []);

    return {
        ...state
    };
};
