import { Avatar, Card, CardContent, Divider, Link, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useAuth } from 'hooks/use-auth';
import { usePageView } from 'hooks/use-page-view';
import { FC, useEffect, useState } from 'react';
import { Scrollbar } from "components/scrollbar";
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { useRouter } from "hooks/use-router";
import { paths } from 'paths';
import { useLogger } from "hooks/use-logger";
import useSmartAccessStore from 'hooks/smart-access/useSmartAccessStore';
import { SmartAccessDialog } from 'components/smart-access/smart-access-dialog';
import { SmartAccess } from 'types/smart-access/smart-access';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PeerdwebColours } from 'theme/peerdweb-colours';
import { toPrettyUserAccessStatus } from 'utils/pretty-pretty';
import { useAssetStore } from 'hooks/assets/useAssetStore';

const OutgoingSmartAccessList: FC = () => {
    const { authUser, peerdwebUser } = useAuth();
    const smartAccessStore = useSmartAccessStore();
    const [log] = useLogger();
    const router = useRouter();
    const [isRevokeDialogOpen, setIsRevokeDialogOpen] = useState(false);
    const [selectedSmartAccess, setSelectedSmartAccess] = useState<SmartAccess | null>(null);

    useEffect(() => {
        smartAccessStore.getOutgoingSmartAccessList();
    }, []);

    usePageView();

    return (
        <>
            <Card>
                <CardContent>
                    <Scrollbar sx={{ '& .simplebar-placeholder': { height: '1px !important' } }}>
                        <Table sx={{ minWidth: 400 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Asset
                                    </TableCell>
                                    <TableCell>
                                        Recipient
                                    </TableCell>
                                    <TableCell>
                                        Status / Date
                                    </TableCell>
                                    <TableCell>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {smartAccessStore.isReady && smartAccessStore.smartAccessList?.map((smartAccess, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Link component={"button"} onClick={() => router.push(paths.assets.view(smartAccess?.asset._id as string))}>
                                                {smartAccess?.asset.headline}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <Tooltip title={smartAccess?.recipient?.email}>
                                                    <Avatar src={smartAccess?.recipient?.imageUrl} sx={{ height: 40, width: 40 }}>
                                                        <SvgIcon>
                                                            <User01Icon />
                                                        </SvgIcon>
                                                    </Avatar>
                                                </Tooltip>
                                                <div>
                                                    <Tooltip title={smartAccess?.recipient?.email}>
                                                        <Typography variant="subtitle2">
                                                            {smartAccess?.recipient?.displayName}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {toPrettyUserAccessStatus(smartAccess?.status)}<br />
                                            <Typography color="text.secondary" variant="subtitle2">
                                                {smartAccess?.createdAt && new Date(smartAccess?.createdAt).toLocaleString('en-GB')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            {smartAccess?.status !== 'decline' && smartAccess?.status !== 'relinquish' && smartAccess?.status !== 'revoke' &&
                                                <>
                                                    <Stack spacing={1} alignItems={'flex-start'}>
                                                        <Link component={"button"} onClick={() => { setSelectedSmartAccess(smartAccess); setIsRevokeDialogOpen(true); }}>
                                                            Revoke Access
                                                        </Link>
                                                    </Stack>
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </CardContent>
            </Card >

            <SmartAccessDialog
                open={isRevokeDialogOpen}
                confirmText='Revoke'
                onConfirm={() => {
                    if (selectedSmartAccess) {
                        smartAccessStore.revoke(selectedSmartAccess._id);
                    }
                    setIsRevokeDialogOpen(false);
                }}
                onCancel={() => {
                    setIsRevokeDialogOpen(false);
                    setSelectedSmartAccess(null);
                }}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                    Revoke Access
                </Typography>
                <Divider />
                <Typography sx={{ py: 2 }}>
                    By revoking this Smart Access, <strong>{selectedSmartAccess?.recipient.displayName}</strong> will no longer be able to view asset <strong>{selectedSmartAccess?.asset.headline}</strong>.<br /><br />
                </Typography>
            </SmartAccessDialog>

        </>
    );
};

export default OutgoingSmartAccessList;
